import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader, IonItemDivider,
    IonLabel,
    IonPage, IonProgressBar,
    IonThumbnail,
    IonTitle,
    IonToggle,
    IonToolbar, useIonActionSheet, useIonToast
} from '@ionic/react';
import {IonList, IonItem, IonImg, IonIcon} from '@ionic/react';
import {isPlatform} from '@ionic/react';
import {
    settingsOutline,
    statsChartOutline,
    snowOutline,
    sunnyOutline, handLeftOutline, waterOutline
} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import styles from './ControlHVAC.module.css';
import IWHVAC from "../client/IWHVAC";
import useUX from "../ux/UX";
import {IonToggleCustomEvent} from "@ionic/core/dist/types/components";
import {ToggleChangeEventDetail} from "@ionic/core";
import classnames from 'classnames';

const ControlHVAC: React.FC = () => {
    const [activeRequests, setActiveRequests] = useState(0);
    const [zones, setZones] = useState<{ zone: string, groups: any[] }[]>([]);
    const [presentActionSheet] = useIonActionSheet();
    const [presentToast] = useIonToast();
    const {isConnecting, presentResult} = useUX();

    function collateGroupsByZone(groups: Record<number, any>) {
        enum Zones {
            ReceptionArea = 'Reception Area',
            Workshop = 'Workshop',
            GroundFloor = 'Ground Floor',
            FirstFloor = 'First Floor'
        }

        let zones: { zone: string, groups: any[] }[] = [
            {zone: Zones.ReceptionArea, groups: []}
        ];

        for (const group of Object.values(groups) as any[]) {
            let zoneKey = 'Other';

            if (['GF Reception', 'FF Recreation'].includes(group.name)) {
                zoneKey = Zones.ReceptionArea;
            } else if (group.name.includes('Workshop')
                || ['GF Photography', 'GF Test Cells'].includes(group.name)) {
                zoneKey = Zones.Workshop;
            } else if (group.name.startsWith('GF')) {
                zoneKey = Zones.GroundFloor;
            } else if (group.name.startsWith('FF')) {
                zoneKey = Zones.FirstFloor;
            }

            const zone = zones.find(z => z.zone === zoneKey);

            if (zone) {
                zone.groups.push(group);
            } else {
                zones.push({
                    zone: zoneKey,
                    groups: [group]
                });
            }
        }

        return zones.filter(z => z.groups.length);
    }

    useEffect(() => {
        const subscription = IWHVAC.groups$.subscribe(groups => {
            setZones(collateGroupsByZone(groups));
        });

        setActiveRequests(r => r + 1);

        IWHVAC.requestGroupsIfNeeded().finally(() => {
            setActiveRequests(r => r - 1);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    async function setOn(groupId: number, on: boolean, e: IonToggleCustomEvent<ToggleChangeEventDetail>) {
        const group = IWHVAC.groups$.getValue()[groupId];

        if (group.on === on) {
            return;
        }

        setActiveRequests(r => r + 1);

        await presentResult(async () => {
            try {
                group.on = on;

                await IWHVAC.sendSetting(groupId, 'on', on);
            } catch (e: any) {
                group.on = !on;
                throw e;
            }
        }, `HVAC #${groupId} turned ${on ? 'on' : 'off'}`);

        setActiveRequests(r => r - 1);
    }

    async function setMode(groupId: number, mode: string | Number) {
        const group = IWHVAC.groups$.getValue()[groupId];
        const oldMode = group.mode;

        if (mode === oldMode) {
            return;
        }

        setActiveRequests(r => r + 1);

        await presentResult(async () => {
            try {
                group.mode = mode;

                await IWHVAC.sendSetting(groupId, 'mode', mode);
            } catch (e: any) {
                group.mode = oldMode;
                throw e;
            }
        }, `HVAC #${groupId} set to ${mode}`);

        setActiveRequests(r => r - 1);
    }

    async function presentHVACModeActionSheet(group: any) {
        if(group.inhibitMode && group.name !== 'GF WOLF Office') {
            return presentToast({
                message: `${group.name} mode is locked`,
                duration: 2500,
                color: 'warning',
                icon: handLeftOutline
            });
        }

        const buttons = [
            {
                text: 'Cool',
                icon: snowOutline,
                handler: () => setMode(group.id, 'cool')
            },
            {
                text: 'Heat',
                icon: sunnyOutline,
                handler: () => setMode(group.id, 'heat')
            },
            {
                text: 'Dry',
                icon: waterOutline,
                handler: () => setMode(group.id, 'dry')
            },
            {
                text: 'Cancel',
                role: 'cancel',
            }
        ];

        for(const button of buttons) {
            if(group.mode === button.text.toLocaleLowerCase()) {
                button.role = 'selected';
            }
        }

        await presentActionSheet({
            header: group.name,
            subHeader: 'Change HVAC Mode',
            buttons: buttons,
        })
    }

    return (
        <IonPage>
            <IonHeader translucent={isPlatform('capacitor')} collapse="fade">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/control"/>
                    </IonButtons>
                    <IonTitle>HVAC</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton routerLink="/control/hvac/graphs">
                            <IonIcon slot="icon-only" icon={statsChartOutline}/>
                        </IonButton>
                    </IonButtons>
                    {!!(isConnecting || activeRequests) && <IonProgressBar type="indeterminate"/>}
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen={true}>
                <IonList>
                    {zones.map(({zone, groups}) => (
                        <React.Fragment key={zone}>
                            <IonItemDivider sticky>{zone}</IonItemDivider>
                            {groups.map((group: any) => (
                                <IonItem key={group.id} className="item-multiple-inputs">
                                    <IonThumbnail slot="start" className="ion-hide-sm-down">
                                        <img src={`/assets/hvac/units/${group.unitType}.png`} alt={group.unitType}/>
                                    </IonThumbnail>
                                    <IonLabel>
                                        <h2>{group.name}</h2>
                                        <p>
                                            <IonIcon icon={settingsOutline}
                                                     style={{marginRight: '3px', marginBottom: '-2px'}}/>
                                            {group.setpoint}℃
                                        </p>
                                    </IonLabel>
                                    <div className={styles.panel}>
                                            <span
                                                className={styles.temperature}>{group.currentTemperature}<sup>°</sup></span>
                                    </div>
                                    <div className={classnames(styles.panel, group.on ? styles.on : styles.off)}
                                        onClick={() => presentHVACModeActionSheet(group)} style={{cursor: 'pointer'}}>
                                        <IonImg src={`/assets/hvac/mode/${group.mode}.png`}/>
                                    </div>
                                    <IonToggle slot="start" checked={group.on} className={styles.toggle}
                                               onIonChange={e => setOn(group.id, e.detail.checked, e)}
                                               disabled={group.inhibitOn}
                                               aria-label={`${group.name} HVAC Toggle`} />
                                </IonItem>
                            ))}
                        </React.Fragment>
                    ))}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default ControlHVAC;
